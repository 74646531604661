.App{
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  background-color: #f9fafc;
}

.BodyPart{
  background-color: #f6f8fa;
}

body {
  margin: 0;
  padding-top: 0;
}

.Sidebar{
  display: none;
  height: auto;
  width: 21vw;
  flex-direction: column;
  background-color: rgb(17,24,39);
  position: sticky;
}

.SidebarList{
  height: 100%;
  width: 100%;
  padding: 0;
}

.SidebarList .row{
  display: flex;
  height: 3vw;
  width: 20vw;
  list-style-type: none;
  padding: 0;
  flex-direction: row;
  color: rgb(203,204,213);
  font-size: 1.2vw;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.row #icon{
  flex: 30%;
  display: grid;
  place-items: end;;
  
}

.row #title{
  flex:70%;
  margin: 0.8vw;
}


.SidebarList .row:hover{
  cursor: pointer;
  border-radius: 1vw;
  background-color: rgb(36,42,56);
}


.SidebarList .Category{
  margin-left: 50px;
  height: 6vh;
  width: 20vw;
  color: rgb(99,104,122);
  font-weight: bolder;
  font-size: 1vw;
}

.Headbar{
  display: none;
  height: 16vw;
  width: 21vw;
  flex-direction: column;
  border-bottom: 0.1pt solid;
  color:rgb(54, 54, 54);
  background-color: rgb(17,24,39);
}


.premium{
  display: none;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(26,33,47);
  width: 18vw;
  height:6vw;
  margin: 2vw;
  border-radius: 10px;
}

#Acme{
  margin-top: 2vw;
  color: white;
  width: 10vw;
  height:1vh;
  display: grid;
  font-weight: bold;
  margin-left: 2vw;
}

#Tier{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgb(229, 231, 240);
  margin:2vw;
}

.logo{
  margin-top: 2.2vw;
  margin-left: 2.77vw;
}

.Footbar{
  display: none;
  flex-direction: column;
  justify-content: space-evenly;
  height: 20vh;
  width: 21vw;
  border-top: 0.1px solid white;
  background-color: rgb(17,24,39);
}

p{
  color: white;
  margin:0;
}

#button{
  margin-left: 2vw;
  width:16vw;
}
#text2{
color: rgb(203,204,213);
}


.Navbar{
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  width: 76vw;
  height:4vw;
  background-color:white;
  position: static;
}

.Navbar div{
  margin: 1vw 2vw 1vw 1vw;
}



.Part1{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Part1 .Part1Flex{
  display: flex;
  flex-direction: row;
  width: 20vw;
  height:10vw;
  justify-content: space-evenly;
  align-items: flex-end;

}

.Part1 #Report{
  width:12vw;
  border-radius: 10px;
  border: 1px solid blue;
}

.Part1 #LastWeek{
  width: 11vw;
  height: 4vw;
  border-radius: 10px;
  border: 1px solid rgb(155, 155, 161);
}

.Part1Flex div{
  margin: 1vw;
}

h1{
  margin-left: 1.5vw;
  margin-top: 8vw;
}

#text{
  color: blue;
}

.Part2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1.5vw;
  margin-bottom: 1.5vw;
  margin-left: 1.5vw;
  width:76vw;
  height: 45vh;
  border-radius: 10px;
  background-color: rgb(80,72,229);

}

#image{
  margin: -0.8vw;
}

#image1{
  width:250px;
  height:250px;
}


.Part2Content div{
  height:2vw;
  color:white;
  font-weight: 500;
  margin-top: 2vw;
  margin-left: 10px;
  margin-right: 2vw;
}

.Part2Content div#Part2ContentText2{
  font-size: 1vw;
}

.Part2Content h1{
  margin: 0;
}
#Part2ContentText1{
  width: 3.2vw;
  border: 0.1px solid rgb(16,185,129);
  overflow: hidden;
  justify-content: center;
  border-radius: 100px;
  background-color: rgb(16,185,129);
}

#Part2ContentText1 p{
  margin-top: 0.2vh;
  margin-left:0.9vh;
  margin-bottom: 0.5vh;
  font-size: small;
}

.Part3{
  display: flex;
  flex-direction: row;
}

.Part3Card{
  margin-top: 2vw;
  margin-left: 2vw;
}

.Card1{
  display: flex;
  width: 480px;
  height: 200px;
  flex-direction: column
}


.Part5{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#Part51{
  margin-top: 2vw;
  margin-left: 2vw;
}

#Part52{
  margin-top: 2vw;
  margin-left: 3vw;
}
 
#Part53{
  margin-top: 3vw;
  margin-left: 2vw;
}

#Part54{
  margin-top: 3vw;
  margin-left: 3vw;
}

.Card{
  display: flex;
  width: 480px;
  height:200px;
  flex-direction: column;
}


@media (min-width:1200px){
  .Footbar{
    display:flex;
  }
  .Headbar{
    display:flex;
  }
  .Sidebar{
    display:flex;
  }

  .premium{
    display:flex;
  }

}


@media (max-width:1200px){
  .Navbar{
    width: 99vw;
  }
  .Part1{
    width:97vw
  }

  .Part2{
    width:97vw;
  }

}

@media only screen and (max-width: 1000px) {
  .Part5{
    flex-direction: column;
  }
.Part2{
  flex-direction: column;
  justify-content:space-evenly;
  align-items: center;
  height:400px
}

.Part2Content{
  margin-bottom: 90px;
}
#image1{
  width:150px;
  height:150px;
}

  .Part3{
    flex-direction: column;
  }

  .Card{
    width: 97vw;
  }

  .Card1{
    width:95vw;
  }
}